import csstype.ClassName
import kotlinx.browser.document
import kotlinx.browser.window
import kotlinx.coroutines.MainScope
import kotlinx.coroutines.delay
import kotlinx.coroutines.launch
import org.w3c.dom.HTMLDivElement
import org.w3c.dom.HTMLInputElement
import react.FC
import react.Props
import react.dom.html.ReactHTML.br
import react.dom.html.ReactHTML.button
import react.dom.html.ReactHTML.h1
import react.dom.html.ReactHTML.input
import kotlin.time.Duration.Companion.seconds

private val scope = MainScope()

val UnlinkMinecraft = FC<Props> {
    button {
        className = ClassName("fail-button")

        +"Verknüpfung aufheben"

        onClick = {
            scope.launch {
                val message = unlink()

                if (message != null && message.code != 200) {
                    val snackbar = document.getElementById("snackbar") as HTMLDivElement
                    snackbar.innerText = message.message
                    snackbar.className = "show"
                    delay(5.seconds)
                    snackbar.className = ""
                    return@launch
                }

                if (message?.code == 200) {
                    window.location.reload()
                }
            }
        }
    }
}

val Whitelist = FC<Props> {
    h1 {
        +"Account verbinden"
    }

    input {
        placeholder = "Spielername"
        id = "ingame-name"
    }

    br {}

    button {
        className = ClassName("success-button")

        +"Whitelisten"

        onClick = {
            var inGameName = (document.getElementById("ingame-name") as HTMLInputElement).value
            scope.launch {
                if (inGameName.isEmpty()) return@launch

                inGameName = inGameName.replace(" ", "")

                val message = whitelistName(inGameName)

                if (message != null && message.code != 200) {
                    val snackbar = document.getElementById("snackbar") as HTMLDivElement
                    snackbar.innerText = message.message
                    snackbar.className = "show"
                    delay(5.seconds)
                    snackbar.className = ""
                    return@launch
                }

                if (message?.code == 200) {
                    window.location.reload()
                }
            }
        }
    }
}