import io.ktor.client.HttpClient
import io.ktor.client.call.body
import io.ktor.client.engine.js.Js
import io.ktor.client.plugins.contentnegotiation.ContentNegotiation
import io.ktor.client.request.get
import io.ktor.client.request.headers
import io.ktor.client.request.post
import io.ktor.client.request.setBody
import io.ktor.client.statement.HttpResponse
import io.ktor.http.ContentType
import io.ktor.http.CookieEncoding
import io.ktor.http.HttpHeaders
import io.ktor.http.contentType
import io.ktor.http.decodeCookieValue
import io.ktor.serialization.kotlinx.json.json
import kotlinx.browser.document
import kotlinx.serialization.json.Json

private val httpClient = HttpClient(Js) {
    install(ContentNegotiation) {
        json(Json {
            ignoreUnknownKeys = true
            encodeDefaults = true
        })
    }
}

suspend fun getMinecraftName(name: String) =
    httpClient.get("https://api.mojang.com/users/profiles/minecraft/$name")
        .bodyOrNull<MojangUniqueId>()

suspend fun whitelistName(inGameName: String) = httpClient.post("/api/whitelist") {
    setBody(WhitelistRequest(inGameName))
    headers {
        append(HttpHeaders.Authorization, accessTokenFromCookie())
    }
    contentType(ContentType.Application.Json)
}.bodyOrNull<HttpMessage>()

suspend fun unlink() = httpClient.post("/api/unlink") {
    headers {
        append(HttpHeaders.Authorization, accessTokenFromCookie())
    }
    contentType(ContentType.Application.Json)
}.bodyOrNull<HttpMessage>()

private fun accessTokenFromCookie(): String {
    return getCookie("auth").replace("accessToken%3D%2523s", "")
}

private fun getCookie(cname: String): String {
    val name = "$cname="
    val decodedCookie = decodeCookieValue(document.cookie, CookieEncoding.RAW)
    val cookies = decodedCookie.split(';')
    for (element in cookies) {
        var cookie = element
        while (cookie.first() == ' ') {
            cookie = cookie.substring(1)
        }
        if (cookie.indexOf(name) == 0) {
            return cookie.substring(name.length, cookie.length)
        }
    }
    return ""
}

suspend inline fun <reified T> HttpResponse.bodyOrNull() = try {
    body<T>()
} catch (_: Exception) {
    null
}