import kotlinx.browser.document
import react.create
import react.dom.client.createRoot

fun main() {
    val root = document.getElementById("root")
    if (root != null) createRoot(root).render(Root.create())

    val login = document.getElementById("login")
    if (login != null) createRoot(login).render(Login.create())

    val unlinkMinecraft = document.getElementById("unlink-mc")
    if (unlinkMinecraft != null) createRoot(unlinkMinecraft).render(UnlinkMinecraft.create())

    val whitelist = document.getElementById("whitelist")
    if (whitelist != null) createRoot(whitelist).render(Whitelist.create())
}